import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";
import dataJesseDrifter from "../../images/photos/old2005BlogPics/20070419/drifter2.jpg";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—April 2007" />
	<Old2005BlogPage>
		<h2>April 2007</h2>



		<h3 className="blogdate">
		Friday, April 20, 2007
		</h3>






		<h4 className="blogitemtitle">
		U want some jello?
		</h4>





			<div className="blogitembody">
			I mean cello? National Radio? New song?<br /><br />It's a 41 minute interview on Radio New Zealand National. The sound quality of the internet broadcast stream is sub-par but it gets by. The beautiful Rachael Linton plays cello with me! Also thanks to Jimmy Stewart and Chris Whitta.<br /><ul><li><em>Mesmerize Me</em> is at approximately 5 mins 30 secs.</li><li><em>Worth the Wait</em> is at roughly 18 mins 20 secs, and they changed the sound mix immediately before this song (sounds better, n'est-ce pas?).</li><li><strong><em>Silent</em> ***new song***</strong> is at nearly-precisely 30 minutes.</li></ul>Here's the linky-link:<br /><a href="https://www.rnz.co.nz/national/programmes/nzlive/audio/2519254/nz-live-jesse-rivest">https://www.rnz.co.nz/national/programmes/nzlive/audio/2519254/nz-live-jesse-rivest</a><br /><br />I may podcast <em>Silent</em> later, we shall see, I think I signed something restrictive in the studio. (<em>Jesse has a podcast? I didn't know that! Where is it? It's right on his website! Oh. It's pretty low-fi! I know, but he has plans...</em>)<br /><br />If I stuck my foot in my mouth during this interview, I assure you that I chewed vigorously.<br /><br />Love,<br /><br />Jesse Rivest
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 5:48 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Thursday, April 19, 2007
		</h3>






		<h4 className="blogitemtitle">
		They called me a drifter!?!
		</h4>





			<div className="blogitembody">
			Click this image to enlarge it.<br /><br /><a href={dataJesseDrifter}><StaticImage
			src="../../images/photos/old2005BlogPics/20070419/drifter2.jpg"
			alt="Jesse Rivest interview on Radio New Zealand in 2007"
			placeholder="blurred"
			layout="constrained"
			className=""
			imgClassName="img--bordered-small"
			width={400}
			/></a><br /><br />I associate <em>drifter</em> with someone who wanders around, aimlessly, wherever the wind blows. I'd say I've had a fair amount of aim this past couple years... drifter I am not. Although it's a romantic notion - drifting around, in and out of people's lives, from town to town. I suppose I've done a <em>bit</em> of that, but I've had some aim, I say!
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 7:58 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Friday, April 06, 2007
		</h3>






		<h4 className="blogitemtitle">
		I have a white afro
		</h4>





			<div className="blogitembody">
			I was about to get on stage last week when a fellow approached me and said, "You know you have a white afro?"<br /><br />I said, "Sure, I guess so."<br /><br />"Yeah man, you have a white afro!"<br /><br />"Okay, cool."<br /><br />"So, I hope you play like you got a white afro."<br /><br />"Uhh..."<br /><br />I think he was a bit drunk. Does anybody know what this could possibly mean? I don't.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 11:22 AM &nbsp;
			</div>





		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
